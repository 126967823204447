import fetch from 'node-fetch'

import { logToCloudWatch } from 'utils/logToCloudWatch'

export default function GetMetaData(version = 'v2') {
  let filename = ''
  if (version === 'v2') {
    filename = 'metadata-v2.json'
  } else if (version === 'v1') {
    filename = 'metadata.json'
  } else {
    logToCloudWatch('Error-JsonInvalid', {
      message: 'Invalid metadata version',
      version: version
    })
  }

  let target = null
  let isLocalEnv = window.location.hostname.includes('localhost')
  let isCmsApi = window.location.hostname.includes('cms-api')

  if ((isLocalEnv || isCmsApi) && version !== 'v2') {
    target = window.$LOCALMETA ? `/local/metadata.${window.$PORTCLIENTMAP[window.location.port]}.json` : `/metadata.json`
    //} else if(version === 'v2') {
    //target = `https://r03-fe-metadata.s3.amazonaws.com/sg.crateandbarrel.localised-int.com-current/metadata-v2.json?cb=${window.$TIMESTAMP}`
  } else {
    target = `https://${window.location.hostname}/${window.location.hostname}-current/${filename}?cb=${window.$TIMESTAMP}`
  }

  return new Promise((resolve, reject) => {
    fetch(target).then((d) => {
      return d.json()
    }).then((json) => {
      resolve(json)
    }).catch(() => {
      reject(`GetMetaData: [FAILED TO FETCH] ${target}`)
    })
  })
}
