import UAparser from 'ua-parser-js'

class Logger {
  constructor() {
    this.logGroup = 'FrontEndLogs'
    this.region = 'us-east-1'
    this.api = window.$ENVIRONMENT === 'production' ? `https://api.localised.com/logger` : 'https://api.localised-staging.com/logger'
    this.parser = new UAparser()
    window.$LOGGER_HOSTNAME = window.location.hostname
  }

  write(type, data = {}) {
    if (!type) {
      return
    }

    const message = {
      ...data,
      shoppingSessionId: window.$shoppingSessionId.value,
      location: `${window.location.pathname}${window.location.search}`,
      about: this.parser.getResult()
    }

    // log to console
    console.error({ type, message })

    // log to cloudwatch
    const logType = encodeURIComponent(type.trim())
    const messageData = encodeURIComponent(JSON.stringify(message).trim())

    this.key = `LITHOS-${window.$TIMESTAMP}-${window.$LOGGER_HOSTNAME}`

    fetch(`${this.api}?logGroup=${this.logGroup}&region=${this.region}&key=${this.key}&type=${logType}&message=${messageData}`)
  }
}

export default Logger
