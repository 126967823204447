import React from 'react'

export const FileInput = props => {
  return (
    <input
      accept=".gif, .jpg, .jpeg, .png, .pdf"
      onChange={handleChange}
      type="file"
      multiple
      value={null}
    />
  )

  function handleChange(e) {
    const { input: { onChange } } = props
    onChange(e.target.files)
  }
}
