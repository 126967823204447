import React from 'react'

import styles from './text-input.css'

const TextArea = ({ disabled, example, input, readOnly, type }) => (
  <div className={styles.wrapper}>
    <textarea
      {...input}
      disabled={disabled}
      onBlur={() => {}} // so as not to require a second click to proceed. see https://github.com/erikras/redux-form/issues/860
      placeholder={example}
      readOnly={readOnly}
      type={type}
    />
  </div>
)

export { TextArea }
