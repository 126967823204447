import * as React from 'react'
import classNames from 'classnames'

import { addToEmailList } from 'services/api'
import { updateGoogleAnalytics } from 'services/analytics'
import { I18n } from 'utils/i18n'
import { logToCloudWatch } from 'utils/logToCloudWatch'
import { storage } from 'utils/storage'
import { Button } from 'components/buttons'
import { BrandedCheckbox } from 'components/inputs'
import { Heading, HtmlText } from 'components/text'

import styles from './email-signup.css'

const emailStorageValue = 'emailListMember'

export const webLabels = {
  cta: `WEB.EMAILLIST.CTA`,
  placeholder: `WEB.EMAILLIST.EMAIL.TEXT`,
  errorExists: `WEB.EMAILLIST.ERROR.EXISTS`,
  errorGeneric: `WEB.EMAILLIST.ERROR.GENERIC`,
  errorFormat: `WEB.EMAILLIST.ERROR.FORMAT`
}

export class EmailSignup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      agreementChecked: false,
      checkboxWarning: false,
      emailError: undefined,
      emailListMember: storage.get(emailStorageValue),
      inputValue: '',
      submittedSuccessfully: false
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const { agreementChecked, inputValue } = this.state
    const inputError = this.validateEmail(inputValue)

    if (inputError) {
      this.setState({
        inputError: inputError
      })
      return
    }

    if (!agreementChecked) {
      this.setState({
        checkboxWarning: true
      })
      return
    }

    addToEmailList(inputValue).then(() => {
      this.setState({
        submittedSuccessfully: true
      })
      updateGoogleAnalytics('emailSignup')
      storage.set(emailStorageValue, true)
    }).catch(err => {
      if (err.status === 409) {
        this.setState({
          inputError: webLabels.errorExists
        })

        logToCloudWatch('Mailchimp error', {
          message: 'Already subscribed to list'
        })
        return
      }

      this.setState({
        inputError: webLabels.errorGeneric
      })

      logToCloudWatch('Mailchimp error', {
        err
      })
    })
  }

  validateEmail(value) {
    const emailValidation = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

    if (!emailValidation.test(value) || !value) {
      return webLabels.errorFormat
    }
  }

  handleChange(e) {
    const { inputError } = this.state
    const inputValue = e.target.value
    const errorMessage = this.validateEmail(inputValue)

    this.setState({
      inputValue: inputValue
    })

    if (inputError) {
      this.setState({
        inputError: errorMessage
      })
    }
  }

  inputToggle(e) {
    this.setState({
      agreementChecked: e.target.checked
    })
  }

  // prevent mobile zoom from input focus, inspiration here: https://stackoverflow.com/a/41487632/3203519
  preventIOsZoomPart1(e) {
    e.target.style.fontSize = '16px'
  }

  preventIOsZoomPart2(e) {
    e.target.style.fontSize = ''
  }

  renderAgreementBar() {
    const { disclaimer } = this.props
    const { checkboxWarning } = this.state

    return (
      <div className={styles.agreementBar}>
        <span className={styles.sentence}>
          <HtmlText html={disclaimer} />
        </span>
        <BrandedCheckbox
          color="white"
          error={checkboxWarning}
          roundedCorners
          input={
            <input
              data-testid="email-signup-checkbox"
              id="emailAgreement"
              name="emailAgreement"
              onChange={this.inputToggle.bind(this)}
              type="checkbox"
            />
          }
        />
      </div>
    )
  }

  render() {
    const { id, successTitle, successSentence, title } = this.props
    const { emailListMember, inputError, inputValue, submittedSuccessfully } = this.state

    const showAgreementBar = inputValue || inputError
    const headingValue = submittedSuccessfully ? successTitle : title

    if (emailListMember) {
      return null
    }

    return (
      <form
        className={styles.container}
        id={id}
        onSubmit={this.onSubmit.bind(this)}
      >
        <div className={styles.helloBar}>
          <Heading
            content={{
              html: headingValue
            }}
            styling={{
              fontFamily: `secondary`,
              fontSize: `xs`,
              universalColor: true
            }}
          />
          {
            !submittedSuccessfully &&
              <div className={styles.submitWrapper}>
                <div className={styles.inputWrapper}>
                  <input
                    className={classNames(styles.input, {
                      [styles.error]: inputError
                    })}
                    data-testid="email-signup-input"
                    type="text"
                    name="email"
                    placeholder={I18n.t(webLabels.placeholder)}
                    onChange={this.handleChange.bind(this)}
                    onMouseDown={this.preventIOsZoomPart1.bind(this)}
                    onFocus={this.preventIOsZoomPart2.bind(this)}
                  />

                  <Button
                    className={styles.button}
                    data-testid="email-signup-submit"
                    styling={{
                      type: `primary`
                    }}
                  >
                    {I18n.t(webLabels.cta)}
                  </Button>
                </div>
                {
                  inputError &&
                  <span className={styles.errorMessage}>
                    {I18n.t(inputError)}
                  </span>
                }
              </div>
          }
          {
            submittedSuccessfully && successSentence &&
            <div className={styles.successfulSentence}>
              <HtmlText html={successSentence} />
            </div>
          }
        </div>
        {inputError &&
          <div className={styles.errorPadding} />
        }
        {showAgreementBar && !submittedSuccessfully && this.renderAgreementBar()}
      </form>
    )
  }
}
