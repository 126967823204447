import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { updateGoogleAnalytics } from 'services/analytics'
import { useRouteChange } from 'hooks'
import { changeLanguage } from 'state/actions'

export function useChangeLanguage() {
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const updateLocation = useRouteChange()

  return function switchLanguage(language, onClick) {
    updateLocation({ pathname, search })
    dispatch(changeLanguage(language))
    updateGoogleAnalytics(`switchLanguage`, {
      text: language
    })

    onClick && onClick()
  }
}
