import React from 'react'

import { CardBadge, CardBanner, CardAdditional } from 'components/product-listing'
import { HighlightBox } from './highlight-box'
import { PromotionAdditional } from './promotion-additional'

const Promotions = ({ area, promotions }) => {
  const componentMap = {
    'productCard/badge': CardBadge,
    'productCard/banner': CardBanner,
    'productCard/additional': CardAdditional,
    'pdp/highlight': HighlightBox,
    'pdp/expandable': PromotionAdditional,
    'pdp/additional': PromotionAdditional
  }

  const Component = componentMap[area]

  if (promotions[area]) {
    return promotions[area].map(promotion => (
      <Component
        key={promotion.key}
        {...promotion}
      />
    ))
  }

  return null
}

export { Promotions }
