import uniq from 'lodash/uniq'

import { getLanguage } from 'utils'
import { postRedirect } from 'utils/postRedirect'
import {
  BRANDCODE_CREDITCARD,
  BRANDCODE_PAYPAL_EXPRESS,
  STATUSCODE_UNAVAILABLE_ITEMS
} from 'utils/constants'
import { getMetaOptions } from 'global-content/config'

import {
  ADD_CART_ITEM,
  ADD_ORDER_MESSAGE,
  APPLY_VOUCHER,
  CLEAR_ORDER_MESSAGES,
  DISABLE_CHECKOUT_SUBMIT,
  FINGERPRINT_DEVICE,
  LOAD_CART_CONTENTS,
  LOAD_COUNTRIES,
  PLACE_ORDER,
  RECREATE_CART,
  REMOVE_REJECTION_ERROR,
  REMOVE_VOUCHER_MESSAGE,
  REMOVE_VOUCHER,
  RESTART_SESSION,
  ROUTE_CHANGE,
  SAVE_SHIPPING_SELECTION,
  START_EXTERNAL_PAYMENT_METHOD,
  STORE_ORDER_REFERENCE,
  UPDATE_CART_ITEM,
  UPDATE_PAYMENT_METHOD,
  UPDATE_USER_LANGUAGE_PREFERENCE
} from 'state/actions'

const initialState = {
  addresses: {},
  cartSummary: {
    totalItemCost: undefined,
    totalCost: undefined,
    totalTax: undefined,
    totalDiscount: undefined,
    totalDiscountProduct: undefined,
    totalDiscountShipping: undefined,
    totalItemCount: 0,
    deliveryCost: undefined,
    voucherCode: undefined,
    voucherName: undefined,
    voucherMessage: undefined
  },
  countries: [],
  deviceFingerprint: null,
  disabledSubmit: false,
  hasLoadedCartContents: false,
  hasProvidedVoucher: false,
  isLoadPending: false,
  isUpdatePending: false,
  isUpdatingVoucher: false,
  items: [],
  lastAction: {},
  orderMessages: [],
  orderReference: null, // necessary for finalizing paypal express checkout
  paymentMethod: {},
  rejectionError: undefined,
  shipping: undefined,
  unavailableItems: []
}

const cart = (state = initialState, action) => {
  switch (action.type) {
  case ADD_ORDER_MESSAGE: {
    return {
      ...state,
      orderMessages: uniq([...state.orderMessages, action.payload])
    }
  }
  case CLEAR_ORDER_MESSAGES: {
    return {
      ...state,
      orderMessages: []
    }
  }
  case DISABLE_CHECKOUT_SUBMIT: {
    return {
      ...state,
      disabledSubmit: true
    }
  }
  case REMOVE_REJECTION_ERROR: {
    return {
      ...state,
      rejectionError: undefined
    }
  }
  case ROUTE_CHANGE: {
    return {
      ...state,
      rejectionError: false,
      hasProvidedVoucher: false
    }
  }
  case `${FINGERPRINT_DEVICE}_FULFILLED`: {
    return {
      ...state,
      deviceFingerprint: action.payload
    }
  }
  case `${RECREATE_CART}_PENDING`:
  case `${LOAD_CART_CONTENTS}_PENDING`:
    return {
      ...state,
      isLoadPending: true
    }
  case `${RECREATE_CART}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload, state.orderMessages),
      lastAction: {
        type: 'recreate'
      },
      isLoadPending: false,
      hasLoadedCartContents: true
    }
  case `${LOAD_CART_CONTENTS}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload, state.orderMessages),
      lastAction: {
        type: 'load'
      },
      isLoadPending: false,
      hasLoadedCartContents: true
    }
  case `${LOAD_CART_CONTENTS}_REJECTED`:
    console.warn('Failed to load cart contents')
    return {
      ...state,
      isLoadPending: false
    }
  case `${LOAD_COUNTRIES}_FULFILLED`:
    return {
      ...state,
      countries: action.payload
    }
  case `${SAVE_SHIPPING_SELECTION}_PENDING`:
    return {
      ...state,
      isUpdatePending: true
    }
  case `${SAVE_SHIPPING_SELECTION}_REJECTED`:
    return {
      ...state,
      isUpdatePending: false
    }
  case `${SAVE_SHIPPING_SELECTION}_FULFILLED`:
    return {
      ...state,
      isUpdatePending: false
    }
  case UPDATE_PAYMENT_METHOD:
    return {
      ...state,
      paymentMethod: paymentMethod(action.payload && action.payload.brandCode)
    }
  case `${ADD_CART_ITEM}_PENDING`:
    return {
      ...state,
      isAddPending: true
    }
  case `${ADD_CART_ITEM}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload.cartResponse, state.orderMessages),
      lastAction: {
        type: 'add',
        sku: action.payload.skuOptions.sku,
        time: new Date().getTime()
      },
      isAddPending: false
    }
  case `${ADD_CART_ITEM}_REJECTED`:
    return {
      ...state,
      isAddPending: false
    }
  case `${UPDATE_CART_ITEM}_PENDING`:
    return {
      ...state,
      isUpdatePending: true
    }
  case `${UPDATE_CART_ITEM}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload.cartResponse, state.orderMessages),
      lastAction: {
        type: 'update',
        sku: action.payload.cartItem.sku,
        time: new Date().getTime()
      },
      isUpdatePending: false
    }
  case `${UPDATE_CART_ITEM}_REJECTED`:
    return {
      ...state,
      isUpdatePending: false
    }
  case `${APPLY_VOUCHER}_PENDING`:
    return {
      ...state,
      hasProvidedVoucher: true,
      isUpdatingVoucher: true
    }
  case `${APPLY_VOUCHER}_REJECTED`:
    return {
      ...state,
      voucher: null,
      isUpdatingVoucher: false
    }
  case `${APPLY_VOUCHER}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload, state.orderMessages),
      isUpdatingVoucher: false
    }
  case `${REMOVE_VOUCHER}_PENDING`:
    return {
      ...state,
      isUpdatingVoucher: true
    }
  case `${REMOVE_VOUCHER}_REJECTED`:
    return {
      ...state,
      isUpdatingVoucher: false
    }
  case `${REMOVE_VOUCHER}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload, state.orderMessages),
      hasProvidedVoucher: false,
      isUpdatingVoucher: false
    }
  case REMOVE_VOUCHER_MESSAGE:
    return {
      ...state,
      hasProvidedVoucher: false
    }

  // so that place Order button is immediately disabled after click
  // stopping duplicate orders: https://brandpath.atlassian.net/browse/LGZ-4287
  case `${UPDATE_USER_LANGUAGE_PREFERENCE}_PENDING`:
  case `${PLACE_ORDER}_PENDING`:
    return {
      ...state,
      rejectionError: false,
      isLoadPending: true
    }
  case `${PLACE_ORDER}_FULFILLED`:
    redirect(action.payload, state.orderReference)
    return {
      ...state
      // isLoadPending: false // wait for redirect
    }
  case `${PLACE_ORDER}_REJECTED`:
    return {
      ...state,
      disabledSubmit: false,
      rejectionError: rejectionError(action.payload?.status),
      isLoadPending: false,
      orderMessages: getOrderMessageRejection(state.orderMessages, action.payload)
    }
  case `${START_EXTERNAL_PAYMENT_METHOD}_FULFILLED`:
    redirect(action.payload)
    return state
  case `${STORE_ORDER_REFERENCE}`:
    return {
      ...state,
      paymentMethod: getMetaOptions('paymentOptions').find(option => option.brandCode === BRANDCODE_PAYPAL_EXPRESS),
      orderReference: action.payload
    }
  case `${RESTART_SESSION}_FULFILLED`:
    return {
      ...initialState,
      paymentMethod: paymentMethod()
    }
  default:
    return state
  }
}

function cartValues(data, orderMessages) {
  const unavailableItems = getUnavailableItems(data.items)

  return {
    ...data,
    unavailableItems,
    orderMessages: getUnavailableItemsOrderMessage(orderMessages, unavailableItems)
  }
}

function getUnavailableItemsOrderMessage(orderMessages, unavailableItems) {
  if (unavailableItems.length && !orderMessages.includes(STATUSCODE_UNAVAILABLE_ITEMS)) {
    return [...orderMessages, STATUSCODE_UNAVAILABLE_ITEMS]
  }

  return orderMessages
}

function getOrderMessageRejection(orderMessages, payload) {
  // TODO WHAT ARE THE STATUS CODES WHERE WE WANT TO SHOW THE ERRORS?

  // if (payload.statusCode && !orderMessages.includes(payload.statusCode)) {
  //   return [...orderMessages, payload.statusCode]
  // }

  return orderMessages
}

function rejectionError(err) {
  if (String(err).startsWith(5)) {
    return `500`
  }

  return err
}

function getUnavailableItems(items) {
  return items.filter(item => item.availability === 'OUTOFSTOCK')
}

function paymentMethod(brandCode) {
  const paymentOptions = getMetaOptions('paymentOptions')
  if (brandCode) {
    return (
      paymentOptions.find(option => option.brandCode === brandCode) ||
      paymentOptions.find(option => option.brandCode === BRANDCODE_CREDITCARD)
    )
  }

  return paymentOptions.find(option => option.brandCode === BRANDCODE_CREDITCARD)
}

function redirect(orderResponse, utr) {
  if (orderResponse && orderResponse.code === 'SUCCESS') {
    const params = {
      Authorization: orderResponse.rawToken,
      Account: orderResponse.siteTag,
      uat: orderResponse.uat,
      utt: orderResponse.utt,
      utr: utr || orderResponse.utr,
      paymentMethod: orderResponse.paymentMethod
    }

    const asQueryString = Object.keys(params).map(param => {
      return `${param}=${params[param]}`
    }).join('&')

    if (orderResponse.redirectURL) {
      postRedirect({
        params,
        url: orderResponse.redirectURL
      })
    } else {
      window.location.href = `/${getLanguage()}/complete?${asQueryString}`
    }
  } else {
    console.error('Told to redirect after an order but there was either no order response or it was not successful', orderResponse)
  }
}

export default cart
