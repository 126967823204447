import React from 'react'

import { FormField } from 'components/form'

export const webLabels = {
  label: `WEB.CHECKOUT.SHIPPING.EMAIL`
}

export const EmailField = () => (
  <FormField
    label={webLabels.label}
    name="consumer.properties.email"
    type="email"
  />
)
