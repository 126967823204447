import variables from './../css/variables.json'

export default {

  init() {
    return this.fetchConfigs().then(() => {
      this.buildHead()
      this.injectStyles()
    })
  },

  buildHead() {
    this.styleSheet = document.createElement('style')
    this.styleSheet.type = 'text/css'
    this.styleSheet.id = 'rootStyles'
    document.head.appendChild(this.styleSheet)
  },

  injectStyles() {
    this.styleSheet.appendChild(document.createTextNode(`:root {`))
    for (let i in this.styles) {
      this.styleSheet.appendChild(document.createTextNode(`  --${i}:${this.styles[i]};`))
    }
    this.styleSheet.appendChild(document.createTextNode(`}`))
  },

  fetchConfigs() {
    return fetch(`/themes/variables.json?cb=${window.$TIMESTAMP}`).then((d1) => {
      return d1.json().then((d2) => {
        this.styles = { ...variables, ...d2 }
      })
    })
  }
}
