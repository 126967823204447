import React from 'react'
import ReactDOM from 'react-dom'
import 'svgxuse'

import store from 'state/reducers'
import { loadedDependencies } from 'state/actions'

import CSSLoader from 'utils/cssLoader'
import Logger from './utils/logger'
import Login from './utils/login'
import ShoppingSessionId from './utils/ShoppingSessionId'
import Maintenance from './utils/maintenance'

import { mailChimp } from 'utils/mailChimp'
import { forter } from 'utils/forter'
import { analytics, favicon, fonts, tracking } from 'utils'
import { loadFonts } from 'utils/loadFonts'
import { sentrySetup } from './utils/sentry'
import { I18n } from 'utils/i18n'
import getMetaData from './utils/getMetaData'
import getConfigData from './utils/getConfigData'
import { siteLoader } from './utils/siteLoader'
import { naverPixel } from './utils/naverPixel'

import {
  loadBadges,
  loadClpRoutes,
  loadHead,
  loadFooter,
  loadFooterNav,
  loadNavigation,
  loadPromotions,
  loadSeoData,
  setGlobalConfig,
  getMetaOptions
} from 'global-content'

import { App } from 'app'
import { AppError } from 'components/errors'

import 'css/reset.css'

// Render loader
siteLoader()

Promise.all([getMetaData('v1'), getMetaData('v2'), getConfigData()]).then(([meta, metaV2, config]) => {
  window.$HISTORY = []
  window.$META = { ...meta, ...metaV2 }
  window.$ENVIRONMENT = getMetaOptions('environment')
  window.$CLIENT = getMetaOptions('clientTag')
  window.$COUNTRY = getMetaOptions('country.code').toLowerCase()
  window.$HASH = getMetaOptions('logins')
  window.$maintenance = new Maintenance()
  window.$logger = new Logger()
  window.$login = new Login()
  window.$login.init()
  window.$shoppingSessionId = new ShoppingSessionId()
  window.$shoppingSessionId.init()
  window.$content = {}

  setGlobalConfig(config)
  sentrySetup()
  loadFonts()
  naverPixel()
  fonts()

  // Dependencies before paint
  I18n.get().then(() => {
    window.$maintenance.init()

    return Promise.all([
      CSSLoader.init(),
      loadClpRoutes(),
      loadSeoData(),
      loadBadges(),
      loadPromotions(),
      loadFooter(),
      loadFooterNav(),
      loadHead(),
      loadNavigation()
    ])
  }).then(() => {
    store.dispatch(loadedDependencies())

    window.ready = true

    // Dependencies after paint
    analytics()
    favicon()
    mailChimp()
    forter()
  }).catch(err => {
    window.error = err.message
  })
})

window.bootstrap = setInterval(() => {
  if (window.supported && window.auth && window.ready) {
    tracking()
    clearInterval(window.bootstrap)
    ReactDOM.render(<App />, document.getElementById('root'))
  }

  if (window.error) {
    clearInterval(window.bootstrap)
    ReactDOM.render(<AppError />, document.getElementById('root'))
  }
}, 60)

if (module.hot) {
  module.hot.accept()
}
