import React from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import { Svg } from 'components/icons'

import styles from './text-input.css'

const iconSize = '3.5rem'

export const TextInput = ({
  cvc,
  disabled,
  example,
  initialValue,
  input,
  meta,
  readOnly,
  secure,
  type
}) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (initialValue && !input.value) {
      dispatch(change(meta.form, input.name, initialValue))
    }
  }, [])

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.cvc]: cvc,
        [styles.secure]: secure
      })}
    >
      <input
        {...input}
        disabled={disabled}
        onBlur={() => {}} // so as not to require a second click to proceed. see https://github.com/erikras/redux-form/issues/860
        placeholder={example}
        readOnly={readOnly}
        type={type}
      />
      {secure &&
          <span className={styles.icon}>
            <Svg icon="lock" />
          </span>
      }

      {cvc &&
        <span className={styles.cards}>
          {cvc === 'CVV' &&
            <Svg
              icon="cvv-credit"
              size={iconSize}
            />
          }
          {cvc === 'CID' &&
            <Svg
              icon="cvv-amex"
              size={iconSize}
            />
          }
        </span>
      }
    </div>
  )
}
