class GooglePay {
  constructor(target) {
    this.target = target
    this.environment = window.$ENVIRONMENT === 'production' ? 'PRODUCTION' : 'TEST'
    this.baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0
    }
    this.allowedCardNetworks = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
    this.allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS']
    this.tokenizationSpecification = {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'adyen',
        gatewayMerchantId: window.$META.localisedEntity.psmMerchantCode
      }
    }
    this.baseCardPaymentMethod = {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: this.allowedCardAuthMethods,
        allowedCardNetworks: this.allowedCardNetworks
      }
    }
    this.cardPaymentMethod = Object.assign({}, this.baseCardPaymentMethod, { tokenizationSpecification: this.tokenizationSpecification })
    this.paymentsClient = null
  }

  googlePayFlow(cart) {
    return this.popupDetection().then(() => {
      return this.buildGooglePay()
    }).then(() => {
      return this.setGooglePaymentsClient()
    }).then(() => {
      return this.isAvailable()
    }).then(() => {
      return this.paymentFlow(cart)
    }).catch((e) => {
      throw e
    })
  }

  popupDetection() {
    return new Promise((resolve, reject) => {
      let isFirefox = navigator.userAgent.includes('Firefox')
      if (isFirefox) {
        let popup = window.open('https://pay.google.com')
        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
          reject(`Please enable popups for this website to continue with GooglePay.`)
        } else {
          popup.close()
          resolve()
        }
      } else {
        resolve()
      }
    })
  }

  buildGooglePay() {
    if (document.getElementById('googlePayScript')) {
      return Promise.resolve()
    } else {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.src = `https://pay.google.com/gp/p/js/pay.js`
        script.id = 'googlePayScript'
        script.type = 'text/javascript'
        script.onload = () => {
          resolve()
        }
        let scripts = document.getElementById('scripts')
        scripts.appendChild(script)
      })
    }
  }

  setGooglePaymentsClient() {
    let onPaymentAuthorized = (paymentData) => {
      let state = paymentData.error ? 'ERROR' : 'SUCCESS'
      return Promise.resolve({ transactionState: state })
    }
    this.paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: this.environment,
      paymentDataCallbacks: {
        onPaymentAuthorized: onPaymentAuthorized
      }
    })
  }

  isAvailable() {
    return new Promise((resolve, reject) => {
      let isReadyToPayRequest = this.getGooglePaymentDataRequest()
      this.paymentsClient.isReadyToPay(isReadyToPayRequest).then((d) => {
        if (d.result) {
          resolve()
        } else {
          reject(`GooglePay.isAvailable: [ERROR] Google Pay unavailable.`)
        }
      }).catch((e) => {
        reject(`GooglePay.isAvailable: [ERROR] ${e}`)
      })
    })
  }

  paymentFlow(cart) {
    const paymentDataRequest = this.getGooglePaymentDataRequest()
    paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo(cart)
    return this.paymentsClient.loadPaymentData(paymentDataRequest).then((d) => {
      console.log(`GooglePay.paymentFlow: [TOKEN]`, d)
      return d.paymentMethodData ? d.paymentMethodData.tokenizationData.token : null
    })
  }

  getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, this.baseRequest)
    paymentDataRequest.allowedPaymentMethods = [this.cardPaymentMethod]
    paymentDataRequest.merchantInfo = {
      merchantId: 'BCR2DN6T5PBKJIIU'
    }
    paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION']
    return paymentDataRequest
  }

  getGoogleTransactionInfo(cart) {
    return {
      displayItems: [
        {
          label: 'Subtotal',
          type: 'SUBTOTAL',
          price: cart.cartSummary.totalItemCost.toString()
        },
        {
          label: 'Shipping',
          type: 'LINE_ITEM',
          price: cart.cartSummary.deliveryCost.toString()
        },
        {
          label: 'Tax',
          type: 'TAX',
          price: cart.cartSummary.totalTax.toString()
        }
      ],
      countryCode: window.$META.country.code.toUpperCase(),
      currencyCode: window.$META.currency.code,
      totalPriceStatus: 'FINAL',
      totalPrice: cart.cartSummary.totalCost.toString(),
      totalPriceLabel: 'Total'
    }
  }
}

export default GooglePay
