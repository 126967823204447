import React from 'react'
import classNames from 'classnames'
import merge from 'lodash/merge'
import get from 'lodash/get'
import { useSelector } from 'react-redux'

import { useContent } from 'hooks'
import { getLevelDown } from 'utils'
import { LinkText } from 'components/text'

import styles from './navigation-menu.css'

export const NavigationMenu = ({
  activeL1,
  activeL2,
  augmentation,
  hoverL1,
  hoverL2,
  linkType,
  onClick,
  onHover
}) => {
  const navTree = useContent('navTree')
  const breadcrumbs = useSelector(state => state.navigation.breadcrumbs)

  const {
    activeState,
    augmentations,
    hoverConnection,
    hoverState = 1,
    level,
    showLevelDown,
    positioning: { itemSpacing }
  } = augmentation
  const augmentedNavTree = merge({}, navTree, augmentations)

  const activeLevel = {
    l1: augmentedNavTree.l1,
    l2: get(augmentedNavTree, `l1.${activeL1}.l2`)
  }

  function activeCompare(slug) {
    return {
      l1: slug === breadcrumbs.length && breadcrumbs[0].slug,
      l2: slug === breadcrumbs.length && breadcrumbs[1] && breadcrumbs[1].slug
    }
  }

  function hoverCompare(slug) {
    return {
      l1: slug === hoverL1,
      l2: slug === hoverL2
    }
  }

  function constructedSlug(slug) {
    return {
      l1: `/${slug}`,
      l2: `/${activeL1}/${slug}`,
      l3: `/${activeL1}/${activeL2}/${slug}`
    }
  }

  if (!activeLevel[level]) {
    return null
  }

  // Use the object supplied for the keys / slugs
  let mapArray = Object.keys(activeLevel[level])

  // Unless there is an order array present, then use this instead.
  if (activeLevel[level].order) {
    mapArray = activeLevel[level].order
  }

  return (
    <React.Fragment>
      {mapArray.map((slug, i) => {
        const { color, label, path, sublabel, target } = activeLevel[level][slug]
        const active = activeCompare(slug)[level]
        const hover = hoverCompare(slug)[level]

        return (
          <div
            className={classNames(styles.linkWrapper, {
              [styles.small]: itemSpacing === `small`,
              [styles.medium]: itemSpacing === `medium`,
              [styles.large]: itemSpacing === `large`,
              [styles.xl]: itemSpacing === `xl`,
              [styles.centerLinkWrapper]: sublabel
            })}
            key={slug}
          >
            <LinkText
              active={active}
              activeState={activeState}
              data-testid={`nav-link-${level}-${i}`}
              hover={hover}
              hoverState={hoverState}
              onClick={onClick}
              onMouseOver={() => onHover(level, slug, hoverConnection)}
              target={target}
              to={path || constructedSlug(slug)[level]}
              style={{ color }}
              type={linkType}
            >
              {label}
              {sublabel && <div className={styles.subLabel}>{sublabel}</div>}
            </LinkText>
            {renderDropdown(activeLevel[level][slug], [activeL1, slug])}
          </div>
        )
      })}
    </React.Fragment>
  )

  function renderDropdown(levelObj, parentSlugs) {
    const nextLevel = getLevelDown(level)
    const levelDown = levelObj[nextLevel]

    if (showLevelDown && levelDown) {
      // Use the object supplied for the keys / slugs
      let dropdownKeys = Object.keys(levelDown)

      // Unless there is an order array present, then use this instead.
      if (levelDown.order) {
        dropdownKeys = levelDown.order
      }

      return (
        <div className={styles.dropdown}>
          {dropdownKeys.map((key, i) => {
            const active = activeCompare(key)[level]
            const hover = hoverCompare(key)[level]

            return (
              <div
                className={styles.dropdownlink}
                key={key}
              >
                <LinkText
                  active={active}
                  activeState={activeState}
                  data-testid={`nav-link-${nextLevel}-${i}`}
                  hover={hover}
                  hoverState={hoverState}
                  to={levelDown[key].path || `/${parentSlugs.join('/')}/${key}`}
                  type={linkType}
                >
                  {levelDown[key].label}
                </LinkText>
              </div>
            )
          })}
        </div>
      )
    }

    return null
  }
}
