import React from 'react'
import onClickOutside from 'react-onclickoutside'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import { currencyFormatted } from 'utils'
import { I18n } from 'utils/i18n'
import { updateGoogleAnalytics } from 'services/analytics'
import { Layout } from 'components/layout'
import { Button } from 'components/buttons'
import { CartCount, MiniCartItem } from 'components/header'
import { Heading, Link } from 'components/text'

import styles from './mini-cart.css'

export const webLabels = {
  heading: `WEB.CART.MINI.HEADING`,
  empty: `WEB.CART.EMPTY`,
  continue: `WEB.CART.CTA.CONTINUE`,
  subtotal: `WEB.CART.SUBTOTAL`,
  cta: `WEB.CART.MINI.CTA`
}

class MiniCartClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  componentDidUpdate(prevProps) {
    const { lastAction, location, totalItemCount } = this.props

    if (
      totalItemCount !== prevProps.totalItemCount &&
      (lastAction.type === 'add' || lastAction.type === 'update') &&
      !location.pathname.includes('/checkout')
    ) {
      this.setState({
        expanded: true
      })
    }
  }

  handleClickOutside() {
    this.closeMiniCart()
  }

  closeMiniCart() {
    if (this.state.expanded) {
      this.setState({
        expanded: false
      })
    }
  }

  handleToggle() {
    if (!this.state.expanded) {
      updateGoogleAnalytics(`clickCart`)
    }

    this.setState({
      expanded: !this.state.expanded
    })
  }

  checkout() {
    const { onCtaClick } = this.props
    updateGoogleAnalytics(`continueFromMiniCart`)
    this.setState({ expanded: false }, () => onCtaClick())
  }

  render() {
    const { expanded } = this.state
    const { cartItems, totalItemCost } = this.props

    return (
      <div
        className={classNames(styles.miniCart, {
          [styles.active]: expanded
        })}
      >
        <CartCount onClick={this.handleToggle.bind(this)} />
        {
          expanded && cartItems &&
          <div
            className={styles.expandedCart}
            data-testid="mini-cart-expanded"
          >
            <div className={styles.title}>
              <Heading
                content={{
                  html: I18n.t(webLabels.heading)
                }}
                styling={{
                  fontFamily: 'secondary',
                  fontSize: 'xs',
                  fontWeight: 500,
                  universalColor: true
                }}
              />
            </div>
            {
              cartItems.length === 0 &&
              <div>
                <Layout
                  className={styles.zeroState}
                  layout={{
                    paddingVertical: `small`
                  }}
                >
                  {I18n.t(webLabels.empty)}
                </Layout>
                <div className={styles.buttonWrapper}>
                  <Link to="/">
                    <Button
                      className={styles.button}
                      config={{
                        tag: `span`
                      }}
                      onClick={this.closeMiniCart.bind(this)}
                      positioning={{
                        buttonHeight: `large`
                      }}
                      styling={{
                        type: `primary`
                      }}
                    >
                      {I18n.t(webLabels.continue)}
                    </Button>
                  </Link>
                </div>
              </div>
            }
            {
              cartItems.length > 0 &&
              <div>
                <div className={styles.viewport}>
                  {cartItems.reverse().map((item, i) => (
                    <MiniCartItem
                      index={i}
                      item={item}
                      key={item.sku}
                    />
                  ))}
                </div>
                <div className={styles.total}>
                  {I18n.t(webLabels.subtotal)}
                  <span className={styles.totalPrice}>
                    {currencyFormatted(totalItemCost)}
                  </span>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    data-testid="mini-cart-proceed-button"
                    onClick={this.checkout.bind(this)}
                    className={styles.button}
                    positioning={{
                      buttonHeight: `large`
                    }}
                    styling={{
                      singleLine: true,
                      type: `primary`
                    }}
                  >
                    {I18n.t(webLabels.cta)}
                  </Button>
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  totalItemCost: state.cart.cartSummary.totalItemCost,
  cartItems: state.cart.items,
  lastAction: state.cart.lastAction,
  totalItemCount: state.cart.cartSummary.totalItemCount
})

export const MiniCart = withRouter(connect(mapStateToProps)(onClickOutside(MiniCartClass)))
