class ApplePay {
  constructor(baseUrl, cart) {
    this.cart = cart
    this.BACKEND_URL_VALIDATE_SESSION = `${baseUrl}checkout/paymentSession`

    this.config = {
      currencyCode: window.$META.currency.code,
      countryCode: window.$COUNTRY.toUpperCase(),
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['amex', 'masterCard', 'maestro', 'visa', 'mada'],
      total: { label: 'Total', amount: this.cart.cartSummary.totalCost, type: 'final' }
    }
    console.log(this.config)
  }

  init() {
    return this.isAvailable().then(() => {
      return this.startApplePaySession()
    })
  }

  isAvailable() {
    return new Promise((resolve, reject) => {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
        resolve()
      } else {
        reject(`ApplePay.isAvailable: [ERROR] ApplePay is unavailable.`)
      }
    })
  }

  startApplePaySession() {
    return new Promise((resolve, reject) => {
      this.applePaySession = new window.ApplePaySession(6, this.config)

      this.applePaySession.onvalidatemerchant = (event) => {
        console.log(`ApplePay.startApplePaySession.onvalidatemerchant: VALIDATION URL ${event.validationURL}`)
        this.validateApplePaySession(event.validationURL).then((merchantSession) => {
          let applePaySessionObject = JSON.parse(merchantSession.applePaySessionObject)
          console.log('merchantSession', applePaySessionObject, this.applePaySession)
          this.applePaySession.completeMerchantValidation(applePaySessionObject)
        }).catch((e) => {
          console.log('merchantSession.error', e)
          console.log(`ApplePay.startApplePaySession.onvalidatemerchant: ERROR ${e}`)
          reject(e)
        })
      }

      this.applePaySession.onpaymentauthorized = (event) => {
        this.applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS)
        console.log(`ApplePay.startApplePaySession.onpaymentauthorized: SUCCESS ${event}`)
        resolve(event)
      }

      this.applePaySession.begin()
    })
  }

  applePayStatusWorker() {
    return new Promise((resolve, reject) => {
      this.statusInterval = setInterval(() => {
        if (this.response) {
          clearInterval(this.statusInterval)
          if (this.response.code === 'SUCCESS') {
            resolve(this.response)
          } else {
            reject(this.response)
          }
        }
      }, 1000)
    })
  }

  validateApplePaySession(validationURL) {
    let is200
    return new Promise((resolve, reject) => {
      return fetch(`${this.BACKEND_URL_VALIDATE_SESSION}`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paymentSessionURL: validationURL
        })
      }).then((d) => {
        is200 = d.ok
        return d.json()
      }).then((d) => {
        if (is200 && d.applePaySessionObject) {
          resolve(d)
        } else {
          reject(d)
        }
      }).catch((e) => {
        reject(e)
      })
    })
  }

  buildResponse(status, data) {
    this.response = {
      code: status,
      data: data
    }
  }
}

export default ApplePay
