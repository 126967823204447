import { get } from 'lodash'

import store from 'state/reducers'

export function constructUrl({ productId, slug }) {
  return `/product/${slug}/${productId}`
}

const PROMOTIONSMAP = {
  all: {
    inputType: 'all'
  },
  brand: {
    inputType: 'array',
    location: 'brand.tag',
    locationType: 'string'
  },
  categories: {
    inputType: 'array',
    location: 'categories',
    locationType: 'array'
  },
  collections: {
    inputType: 'array',
    location: 'collections',
    locationType: 'array',
    arrayKey: 'tag'
  },
  onSale: {
    inputType: 'boolean',
    location: 'sale',
    locationType: 'string',
    truthyValues: ['ONSALE', 'FINALSALE']
  }
}

export function getPromotions(product) {
  const activeLanguage = store.getState().language.active
  const productPromotions = window.$content.promotions[activeLanguage].products || []
  const promotions = {}

  productPromotions.forEach(promotion => {
    const { filters } = promotion
    const filtersArr = Object.keys(filters)
    let showPromotion = false

    for (let i = 0; i < filtersArr.length; i++) {
      const filter = filtersArr[i]
      const value = filters[filtersArr[i]]
      const { arrayKey, inputType, location, locationType, truthyValues } = PROMOTIONSMAP[filter]

      // Show in all places
      if (inputType === 'all') {
        showPromotion = true
      }

      if (inputType === 'array') {
        const { excludes, includes } = value
        let locationData

        if (locationType === 'array') {
          locationData = get(product, `${location}`, [])

          if (arrayKey) {
            locationData = locationData.map(item => item[`${arrayKey}`])
          }

          if (includes) {
            if (includes.some(cat => locationData.includes(cat))) {
              showPromotion = true
            } else {
              showPromotion = false
              break
            }
          }

          if (excludes && excludes.some(cat => locationData.includes(cat))) {
            showPromotion = false
            break
          }
        }

        if (locationType === 'string') {
          locationData = get(product, location, '')

          if (includes) {
            if (includes.includes(locationData)) {
              showPromotion = true
            } else {
              showPromotion = false
            }
          }

          if (excludes && excludes.includes(locationData)) {
            showPromotion = false
          }
        }
      }

      // search string for match
      if (inputType === 'string') {
        const stringInData = get(product, location, '')

        if (stringInData === value) {
          showPromotion = true
        } else {
          showPromotion = false
        }
      }

      // search boolean for match
      if (inputType === 'boolean') {
        let locationData = get(product, location, '')

        if (locationType === 'number') {
          locationData = !!locationData
        }

        if (locationType === 'string') {
          locationData = truthyValues.includes(locationData)
        }

        if (value === locationData === true) {
          showPromotion = true
        }

        if (value === locationData === false) {
          showPromotion = false
          break
        }
      }
    }

    if (showPromotion) {
      promotion.locations.forEach(promo => {
        // if key already exists add to it
        if (promotions[promo.config.location]) {
          promotions[promo.config.location].unshift(promo)
        // else make a new key and array
        } else {
          promotions[promo.config.location] = [promo]
        }
      })
    }
  })

  return promotions
}
