import mergeWith from 'lodash/mergeWith'
import get from 'lodash/get'

import { setAlgoliaIndices } from 'global-content/algolia'
import { setComponents } from 'global-content/components'
import { setShippingOptions } from 'global-content/shippingOptions'
import { setThemes } from 'global-content/themes'
import { customizer } from 'utils/mergeCustomizer'
import { setPrefixedUrl } from 'utils/setPrefixedUrl'

export function setGlobalConfig(config) {
  window.$CONFIG = mergeWith({}, config, customizer)

  setPrefixedUrl()
  setAlgoliaIndices()
  setShippingOptions() // TODO: move to a separate JSON file / api call
  setComponents() // TODO: move to a separate JSON file / api call
  setThemes() // TODO: move to a separate JSON file / api call
}

export function getConfigOptions(key) {
  return get(window.$CONFIG, key)
}

export function getMetaOptions(key) {
  // todo: find a better way to add hooks
  if (key === 'currencyCountry') {
    return `${getMetaOptions('currency.code')}-${getMetaOptions('country.code').toUpperCase()}`
  }
  return get(window.$META, key)
}
