import getMetaData from './getMetaData'
import { I18n } from './i18n'

export const webLabels = {
  title: `WEB.MAINTENANCE.HEADING`,
  desc: `WEB.MAINTENANCE.MESSAGE`
}

export default class Maintenance {
  constructor() {
    this.target = document.body
    this.pollLock = false
    this.pollTimeMs = 1000 * 60
    this.hadMaintenance = false
  }

  init() {
    this.pollStatus()
    setTimeout(() => {
      this.showPage(window.$META)
    }, 60)
  }

  buildStyle() {
    document.head.insertAdjacentHTML('beforeend', `
      <style>
        #maintenance{
          position: absolute;
          padding:30px;
          top:0px;
          left:0px;
          right:0px;
          bottom:0px;
          display:flex;
          flex-direction:column;
          align-items:center;
          justify-content: center;
          align-content:center;
          z-index:1000;
          background:#fff;
          transition: all 0.5s ease;
          pointer-events:none;
          opacity:0;
        }
        #maintenance.active{
          pointer-events:all;
          opacity:1;
        }
        #maintenance .icon{
          fill: #000;
          height:140px;
          width:140px;
        }
        #maintenance .heading{
          font-size:36px;
          text-transform: uppercase;
          margin-top:30px;
        }
        #maintenance .message{
          font-size:18px;
          margin-top:30px;
        }
      </style>
    `)
  }

  buildShell() {
    this.handle = document.createElement('div')
    this.handle.id = 'maintenance'
    this.target.appendChild(this.handle)
    this.handle.insertAdjacentHTML('beforeend', `
      <img class="icon" src="/images/maintenance.png" />
      <div class="heading">${I18n.t(webLabels.title)}</div>
      <div class="message">${I18n.t(webLabels.desc)}</div>
    `)
  }

  pollStatus() {
    this.interval = setInterval(() => {
      if (!this.pollLock) {
        this.pollLock = true
        getMetaData().then((d) => {
          this.showPage(d)
          this.pollLock = false
        })
      }
    }, this.pollTimeMs)
  }

  showPage(d) {
    if (d.inMaintenance) {
      this.buildStyle()
      this.buildShell()
      this.hadMaintenance = true
      this.handle.classList.add('active')
      document.body.style.overflow = 'hidden'
    } else {
      if (this.hadMaintenance) {
        window.location.reload()
      }
    }
  }
}
