import React from 'react'
import classNames from 'classnames'

import { Svg } from 'components/icons'

import styles from './flyout.css'

export const Flyout = ({
  children,
  close,
  'data-testid': testId,
  type
}) => (
  <div
    className={classNames(styles.flyout, styles[type])}
    data-testid={testId}
  >
    {children}
    {close && (
      <button
        className={styles.button}
        onClick={close}
      >
        <Svg icon="close" />
      </button>
    )}
  </div>
)
