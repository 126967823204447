import React from 'react'
import classNames from 'classnames'

import { I18n } from 'utils/i18n'

import { SelectableBox, SwatchButton } from 'components/buttons'

import styles from './swatches.css'

export const Swatches = ({
  'data-testid': testId,
  limit,
  onSelect,
  options,
  selectedColor,
  showNonSwatches,
  swatchSize,
  swatchType
}) => {
  let optionsUpToLimit = options
  if (typeof limit === 'number') {
    optionsUpToLimit = options.slice(0, limit)
  }

  const maxLength = Math.max(optionsUpToLimit.map(option => option.color.name.length))
  const anyWithoutSwatch = !!optionsUpToLimit.filter(option => !option.color.hex && !option.color.swatch).length

  let inline = {
    gridTemplateColumns: `repeat(4, 1fr)`
  }

  if (maxLength > 10) {
    inline = {
      // Assumption is a character's width is ~0.8 of 1em
      gridTemplateColumns: `repeat(auto-fill, minmax(${maxLength * 0.8}em, 1fr))`
    }
  }

  return (
    <React.Fragment>
      <div
        className={classNames(styles.swatchSet, {
          [styles.grid]: anyWithoutSwatch
        })}
        style={inline}
      >
        {renderColors()}
        {limit < options.length && options.length > 1 &&
          <div className={styles.additional}>
            {I18n.t('WEB.PLP.SWATCHES.ADDITIONAL', {
              replace: {
                number: options.length - limit
              }
            })}
          </div>
        }
      </div>
    </React.Fragment>
  )

  function renderColors() {
    return optionsUpToLimit.map((option, i) => {
      const {
        availability,
        color,
        slug
      } = option

      const {
        hex,
        name,
        swatch
      } = color

      const buttonTestId = `${testId}-${i}`
      return (
        <div
          className={classNames(styles.wrapper, styles[swatchSize])}
          key={name}
        >
          {anyWithoutSwatch && showNonSwatches &&
            <SelectableBox
              data-testid={buttonTestId}
              disabled={!['AVAILABLE', 'LOWSTOCK'].includes(availability)}
              label={name}
              onClick={() => onSelect(slug)}
              selected={selectedColor === slug}
            />
          }

          {!anyWithoutSwatch &&
            <SwatchButton
              data-testid={buttonTestId}
              disabled={!['AVAILABLE', 'LOWSTOCK'].includes(availability)}
              label={name}
              onClick={() => onSelect(slug)}
              selected={selectedColor === slug}
              swatchHex={hex}
              swatchPath={swatch}
              swatchSize={swatchSize}
              swatchType={swatchType}
            />
          }
        </div>
      )
    })
  }
}
