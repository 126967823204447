import React from 'react'

import { Svg } from 'components/icons'
import { Image } from 'components/images'

import styles from './card-badge.css'

export const CardBadge = ({
  content = {},
  styling = {}
}) => {
  const { iconRef, imageSrc, text } = content
  const { backgroundColor, color } = styling

  const inline = {
    backgroundColor,
    color
  }

  function renderBadge() {
    if (imageSrc) {
      return (
        <Image src={imageSrc} />
      )
    }

    if (iconRef) {
      return (
        <Svg
          icon={iconRef}
          size="5rem"
        />
      )
    }

    return (
      <div
        className={styles.text}
        style={inline}
      >
        {text}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderBadge()}
    </div>
  )
}
