import React from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'

import { I18n } from 'utils/i18n'

import { Flyout } from 'components/flyout'
import { CountryDropdown, Dropdown, FileInput, TextArea, TextInput } from 'components/form'
import { Tooltip } from 'components/tooltip'

import styles from './form-field.css'

export const webLabels = {
  commonsValidator: 'WEB.CHECKOUT.FORM.ERROR.FORMAT',
  enum: 'WEB.CHECKOUT.FORM.ERROR.INVALID',
  format: 'WEB.CHECKOUT.FORM.ERROR.FORMAT',
  invalid: 'WEB.CHECKOUT.FORM.ERROR.INVALID',
  maxLength: 'WEB.CHECKOUT.FORM.ERROR.MAXLENGTH',
  minLength: 'WEB.CHECKOUT.FORM.ERROR.MINLENGTH',
  notAccepted: 'WEB.CHECKOUT.FORM.ERROR.NOTACCEPTED',
  pattern: 'WEB.CHECKOUT.FORM.ERROR.INVALID',
  required: 'WEB.CHECKOUT.FORM.ERROR.REQUIRED',
  fileAttachment: 'WEB.CONTACT-US.FIELD.ATTACHMENT.TOO-BIG'
}

const LazyTelephoneInput = React.lazy(() => (
  import(/* webpackChunkName: "telephone" */ 'components/form/telephone-input')
))

const TelephoneInput = props => (
  <React.Suspense fallback={<div />}>
    <LazyTelephoneInput {...props} />
  </React.Suspense>
)

export const FormField = props => {
  const { conditionalRender, formValues } = props
  let render = true

  if (conditionalRender && formValues) {
    // Set the render as false by default
    render = false
    const { when, operator = 'AND' } = conditionalRender

    for (let i = 0; i < when.length; i++) {
      const condition = when[i]
      const { field, value } = condition
      const { includes = [], excludes } = value

      render = includes.indexOf(formValues[field]) > -1

      if (excludes) {
        render = excludes.indexOf(formValues[field]) < 0
      }

      if (operator === 'OR' && render) {
        break
      }
    }
  }

  if (render) {
    return (
      <Field
        {...props}
        component={renderField}
      />
    )
  }

  return null
}

function renderField(props) {
  const {
    label,
    name,
    readOnly,
    type,
    meta: { touched, error },
    tooltip,
    input
  } = props

  const componentMap = {
    email: TextInput,
    file: FileInput,
    text: TextInput,
    textarea: TextArea,
    select: Dropdown,
    phone: TextInput,
    phoneFormat: TelephoneInput,
    tel: TextInput,
    country: CountryDropdown
  }

  const Component = componentMap[type]

  return (
    <div className={styles.container}>
      {label &&
        <div className={styles.wrapper}>
          <label
            className={styles.label}
            htmlFor={name}
          >
            {I18n.t(label)}
          </label>
          {tooltip &&
            <Tooltip
              name={input.name}
              image={tooltip.image}
              positioning={['bottom', 'right']}
              title={tooltip.header}
              value={tooltip.description}
            />
          }
        </div>
      }
      <div className={classNames(styles.inputWrapper, styles[type], {
        [styles.readOnly]: readOnly
      })}>
        <Component {...props} />
      </div>
      {touched && error && renderError(error, label, input.name)}
    </div>
  )
}

function renderError(error, label, name) {
  let errorMessage = I18n.t(webLabels[error], {
    logMissing: false
  })

  if (errorMessage) {
    // todo: use interpolation
    errorMessage = errorMessage.replace('{{fieldName}}', I18n.t(label))
  }

  return (
    <Flyout
      data-testid={`flyout-${name}`}
      type="error"
    >
      {errorMessage}
    </Flyout>
  )
}
