import * as Sentry from '@sentry/browser'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import reduce from 'lodash/reduce'
import map from 'lodash/map'

import { getMetaOptions } from 'global-content/config'
import { logToCloudWatch } from 'utils/logToCloudWatch'
import { I18n } from 'utils/i18n'

export function translate(content, language, returnSomething, chain = []) {
  const languages = getMetaOptions('languages')
  const country = getMetaOptions('country.code').toLowerCase()
  let lang = language

  if (!(languages.indexOf(lang) > -1)) {
    logToCloudWatch('Translate Error', {
      // explicit logging. Should pair up with what is being bubbled up
      value: `Cannot translate unsupported language "${lang}". Available options: ${languages}`
    })

    lang = getMetaOptions('defaultLanguage')
  }

  const getTranslatedValue = item => {
    return reduce(
      item,
      (acc, val, key) => {
        if (isPlainObject(val)) {
          if (
            val[`${lang}-${country}`] ||
            val[`${lang}-${country.toUpperCase()}`] ||
            val[lang] ||
            isNull(val[lang]) ||
            val[lang] === ``
          ) {
            acc[key] = val[`${lang}-${country}`] || val[`${lang}-${country.toUpperCase()}`] || val[lang]
          } else {
            acc[key] = translate(val, lang, returnSomething, [...chain, key])
          }
        } else if (isArray(val)) {
          acc[key] = translate(val, lang, returnSomething, [...chain, key])
        } else {
          acc[key] = I18n.t(val, {
            language: lang
          })
        }
        return acc
      },
      {}
    )
  }

  if (isPlainObject(content)) {
    const keys = Object.keys(content)
    if (
      languages.some(lng => keys.includes(lng)) &&
      !keys.includes(lang)
    ) {
      if (returnSomething) {
        return content.en || ``
      } else {
        logTranslationError(lang, content, chain)
        return ``
      }
    }

    return getTranslatedValue(content)
  }

  if (isString(content) || isNumber(content)) {
    return I18n.t(content)
  }

  return map(content, item => isPlainObject(item) ? getTranslatedValue(item) : item)
}

const sentryErrorMap = {
  // these are known, so start off in the map not to fire events for them
  'fields.phoneticSurname.label.en': `known`,
  'fields.phoneticSurname.example.en': `known`,
  'fields.phoneticGivenName.example.en': `known`,
  'fields.phoneticGivenName.label.en': `known`
}

function logTranslationError(lang, content, chain) {
  const message = `Missing language key "${lang}". Available content: ${JSON.stringify(content)}`
  const position = [...chain, lang].join('.')

  if (!sentryErrorMap[position]) {
    sentryErrorMap[position] = message

    logToCloudWatch('Missing language key', {
      position,
      value: message
    })

    Sentry.withScope(scope => {
      Object.keys(content).forEach(key => {
        scope.setExtra(key, content[key])
      })
      Sentry.captureMessage(`Missing language key ${position}`)
    })
  }
}

export function translatedOptions(data) {
  const translated = {}

  getMetaOptions('languages').forEach(language => {
    translated[language] = translate(data, language)
  })

  return translated
}
