import get from 'lodash/get'

import { loadContent } from 'services/content'
import { translatedOptions } from 'utils/translate'

export function getContent(key, language) {
  const content = get(window.$content, key)

  if (content) {
    return content[language] || content
  }
}

export function loadBadges() {
  return loadContent('/data/badges.json').then(data => {
    window.$content.badges = translatedOptions(data.content)
  })
}

export function loadFooter() {
  return loadContent('/data/footer.json').then(data => {
    window.$content.footer = translatedOptions(data.content)
  })
}

export function loadFooterNav() {
  return loadContent('/data/footerNav.json').then(data => {
    window.$content.footerNav = translatedOptions(data.content)
  })
}

export function loadHead() {
  return loadContent('/data/head.json').then(data => {
    window.$content.head = translatedOptions(data.content)
  })
}

export function loadNavigation() {
  return Promise.all([
    loadContent('/data/navTree.json'),
    loadContent('/data/navMenu.json')
  ]).then(([navTree, navMenu]) => {
    window.$content.navTree = translatedOptions(navTree.content)
    window.$content.navMenu = translatedOptions(navMenu.content)
  })
}

export function loadPromotions() {
  return loadContent('/data/promotions.json').then(data => {
    window.$content.promotions = translatedOptions(data.content)
  })
}

export function loadClpRoutes() {
  // returns list of CLP routes from a json file generated during build process
  // in lithos-content. these routes are then injected into our router
  return loadContent('/data/clp-routes.json').then(data => {
    window.$content.routes = {
      list: data.content
    }
  })
}

export function loadSeoData() {
  return loadContent('/data/seoData.json').then(data => {
    window.$content.seoData = translatedOptions(data.content)
  })
}
