import React from 'react'
import MarkdownIt from 'markdown-it'
import { useDispatch, useSelector } from 'react-redux'

import { prerenderReady } from 'state/actions'
import { I18n } from 'utils/i18n'
import { useDataFetching } from 'hooks/useDataFetching'
import { HtmlText } from 'components/text'
import { Layout } from 'components/layout'

import styles from './mark-down-content.css'

const md = new MarkdownIt({ breaks: true })

export const MarkDownContent = ({
  content = {},
  positioning = {},
  styling
}) => {
  const activeLanguage = useSelector(state => state.language.active)
  const folder = `/pages`
  const source = content.src && `${content.src}-${activeLanguage}.md`
  const [data] = useDataFetching({ folder, source })
  const { contentSeparation, maxWidth } = positioning

  return (
    <div
      className={styles.container}
      data-testid="markdown"
      style={{
        maxWidth
      }}
    >
      {renderContent()}
    </div>
  )

  function renderContent() {
    if (content.ref) {
      return content.ref.map(item => (
        <Layout
          key={item}
          layout={{
            marginBottom: contentSeparation
          }}
        >
          <MarkDownContentComponent
            data={I18n.t(item)}
            styling={styling}
          />
        </Layout>
      ))
    }

    if (content.src) {
      return (
        <MarkDownContentComponent
          data={I18n.interpolate(data)}
          styling={styling}
        />
      )
    }
  }
}

const MarkDownContentComponent = ({ data, styling }) => {
  const dispatch = useDispatch()

  if (data) {
    dispatch(prerenderReady('[data-testid="markdown"]'))

    return (
      <HtmlText
        html={md.render(data)}
        {...styling}
      />
    )
  }

  return null
}
