import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { useRouteChange } from 'hooks'
import { prerenderReady } from 'state/actions'
import {
  cancelAction,
  loadFiltersForPage,
  loadFullFilterSet,
  loadProducts
} from 'state/actions/listing'

import { Factory } from 'components/factory'

export const Listing = ({ items }) => {
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const activeLanguage = useSelector(state => state.language.active)
  const loadedLanguages = useSelector(state => state.listing.loadedLanguages)
  const currentPage = useSelector(state => state.navigation.currentPage)
  const products = useSelector(state => state.listing.products)
  const updateLocation = useRouteChange()
  const { path } = currentPage
  const { page, query, sortBy } = queryString.parse(search)

  React.useEffect(() => {
    // this updateLocation to cater for native browser actions: back / forward events
    // certain query parameters act like a route change (page, query and sortBy)
    // as side effects start at the bottom of the tree and bubble up
    // we have to force a route change here so that when fetching products
    // we have the most up to date search parameters
    updateLocation({ pathname, search, force: true })
    /******/

    const actionId = Math.random()
    getListingContent(actionId)

    if (products.length) {
      dispatch(prerenderReady('[data-testid="product-card-1"]'))
    }

    return () => dispatch(cancelAction(actionId))
  }, [path, activeLanguage, page, query, sortBy])

  return (
    <Factory items={items} />
  )

  async function getListingContent(actionId) {
    if (!loadedLanguages.includes(activeLanguage)) {
      await dispatch(loadFullFilterSet(activeLanguage))
    }

    dispatch(loadFiltersForPage())
    dispatch(loadProducts(actionId))
  }
}
